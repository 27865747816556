import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    details: {
      name: '',
      address: '',
      phone: '',
      email: '',
      fbPage: '',
    },
    video: {
      file1Url: '',
      file2Url: '',
    },
    layout: {
      isMobileMenuVisible: false,
    },
  },
  mutations: {
    updateMobileMenuVisible(state, value) {
      state.layout.isMobileMenuVisible = value;
    },
  },
  actions: {},
  modules: {},
});
